import '@mrhenry/wp--bugsnag-config';
import { MrTabs, PrivacyControls, initMenuButtons } from '@mrhenry/wp--mr-interactive';

PrivacyControls.setupPrivacyAwareEmbedders();
PrivacyControls.setupPrivacyAwareGTM();

initMenuButtons();

import './modules/inline-video';
import './modules/lazy-resengo';
import './modules/marquee';
import './modules/menu-locations';
import './modules/mews-book-now';
import './modules/mr-booking-dialog';
import './modules/mr-dialog';
import './modules/mr-input-sink';
import './modules/mr-newsletter-dialog-toggle';
import './modules/mr-popup';
import './modules/privacy-dialog';
import './modules/service-icons';
import './modules/slideshow';
import './modules/video-embed-player';


import './helpers/overlay';

customElements.define( 'mr-tabs', MrTabs );
