import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-inline-video', {
	controller: class extends BaseController {

		bind() {
			const item = this.el.querySelector( '.js-inline-video__item' );
			if ( !item ) {
				return;
			}

			this.once( 'playing', () => {
				this.el.classList.add( 'is-playing' );
			}, item );
		}
	},
} );
