function setupMewsBookNowDates() {
	const location = document.getElementById( 'booking-city-hotel' ) as HTMLSelectElement | null;
	if ( !location ) {
		return;
	}

	const selectedOption = location.options[location.selectedIndex];
	if ( !selectedOption ) {
		return;
	}

	location.addEventListener( 'change', onChangeLocation );
	const openingDate = selectedOption.getAttribute( 'opening-date' );
	setBookNowDates( openingDate );

	const startDateInput = document.getElementById( 'mews-check-in-date' ) as HTMLInputElement | null;
	if ( !startDateInput ) {
		return;
	}

	startDateInput.addEventListener( 'change', onChangeStartDate );
}

function onChangeLocation( event: Event ) {
	const location = event.currentTarget as HTMLSelectElement | null;
	if ( !location ) {
		return;
	}

	const selectedOption = location.options[location.selectedIndex];
	if ( !selectedOption ) {
		return;
	}


	const openingDate = selectedOption.getAttribute( 'opening-date' );

	setBookNowDates( openingDate );
}

function onChangeStartDate( event: Event ) {
	const startDateInput = event.currentTarget as HTMLInputElement;
	const endDateInput = document.getElementById( 'mews-check-out-date' ) as HTMLInputElement | null;

	if ( !endDateInput ) {
		return;
	}

	if ( startDateInput && endDateInput ) {
		endDateInput.setAttribute( 'min', startDateInput.value );

		if ( endDateInput.value <= startDateInput.value ) {
			endDateInput.value = '';
		}
	}
}

function setBookNowDates( openingDate: string | null ) {
	const startDateInput = document.getElementById( 'mews-check-in-date' ) as HTMLInputElement | null;
	const endDateInput = document.getElementById( 'mews-check-out-date' ) as HTMLInputElement | null;

	if ( !startDateInput || !endDateInput ) {
		return;
	}

	let startDate = new Date();
	if ( openingDate && new Date( openingDate ) > startDate ) {
		startDate = new Date( openingDate );
	}

	const twoDaysFromNow = new Date( startDate );
	twoDaysFromNow.setDate( twoDaysFromNow.getDate() + 2 );

	const startDateString = startDate.toJSON().split( 'T' )[0];
	const endDateString = twoDaysFromNow.toJSON().split( 'T' )[0];

	startDateInput.setAttribute( 'min', ( startDateString ) );
	startDateInput.setAttribute( 'value', ( startDateString ) );
	startDateInput.setAttribute( 'placeholder', ( startDateString ) );

	endDateInput.setAttribute( 'min', ( startDateString ) );
	endDateInput.setAttribute( 'value', ( endDateString ) );
	endDateInput.setAttribute( 'placeholder', ( endDateString ) );
}

window.addEventListener( 'load', () => {
	setupMewsBookNowDates();
} );
