import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import { throttle } from '../helpers/throttle';

const selectors = {
	btnNext: '.js-mr-service-icons__next',
	btnPrev: '.js-mr-service-icons__previous',
	item: '.js-mr-service-icons__slide',
};

defineCustomElement( 'mr-service-icons', {
	attributes: [
		{
			attribute: 'loop',
			type: 'bool',
		},
		{
			attribute: 'current',
			type: 'int',
		},
	],
	controller: class extends BaseController {

		/*
		 * Step 1
		 */
		resolve() {
			return new Promise( ( resolve ) => {
				requestAnimationFrame( () => {
					this.items = Array.from( this.el.querySelectorAll( selectors.item ) );

					if ( 1 > this.items.length ) {
						// Keep hanging, don't activate if empty
						return;
					}

					return resolve();
				} );
			} );
		}

		/*
		 * Step 2
		 */
		init() {
			this.btnNext = Array.from( this.el.querySelectorAll( selectors.btnNext ) );
			this.btnPrev = Array.from( this.el.querySelectorAll( selectors.btnPrev ) );

			if ( !this.current ) {
				this.setCurrent( 0 );
			}
		}

		/*
		 * Step 3
		 */
		render() {
			this.items.forEach( ( item, i ) => {
				if ( item.classList.contains( 'is-active' ) ) {
					item.classList.remove( 'is-active' );
				}

				if ( i === this.current ) {
					item.classList.add( 'is-active' );
				}
			} );
		}

		/*
		 * Step 4
		 */
		bind() {
			/**
			 * On click next button handler
			 */
			const throttledToNext = throttle( () => {
				return this.next();
			}, 256 );

			this.btnNext.forEach( ( el ) => {
				this.on( `click ${selectors.btnNext}`, ( e ) => {
					e.preventDefault();

					this.auto = 0;
					throttledToNext();
				}, el );
			} );

			/**
			 * On click previous button handler
			 */
			const throttledToPrev = throttle( () => {
				return this.previous();
			}, 256 );

			this.btnPrev.forEach( ( el ) => {
				this.on( `click ${selectors.btnPrev}`, ( e ) => {
					e.preventDefault();

					this.auto = 0;
					throttledToPrev();
				}, el );
			} );

			const touch = {
				startX: 0,
				threshold: 100,
				allowedTime: 400,
				startTime: 0,
			};

			this.on( 'touchstart', ( e ) => {
				touch.startX = e.changedTouches[0].screenX;
				touch.startTime = new Date().getTime();
			} );

			this.on( 'touchend', ( e ) => {
				const distX = e.changedTouches[0].screenX - touch.startX;
				if ( new Date().getTime() - touch.startTime <= touch.allowedTime &&
					Math.abs( distX ) >= touch.threshold ) {
					if ( 0 > distX ) {
						this.next();
					} else {
						this.previous();
					}
				}
			} );
		}

		/***/

		setCurrent( to ) {
			let parsed = parseInt( to, 10 );

			if ( parsed === this.current ) {
				return;
			}

			const max = this.items.length;

			// If we're at the last slide and navigated 'Next'
			if ( parsed >= max ) {
				// Back to first slide if carousel has loop set to true
				if ( this.loop ) {
					parsed = 0;
				} else {
					parsed = max - 1;
				}
			}

			// If we're at the first slide and navigated 'Previous'
			if ( 0 > parsed ) {
				// Jump to last slide if carousel has loop set to true
				if ( this.loop ) {
					parsed = max - 1;
				} else {
					parsed = 0;
				}
			}

			this.current = parsed;

			this.render();
		}

		next() {
			this.setCurrent( this.current + 1 );
		}

		previous() {
			this.setCurrent( this.current - 1 );
		}

		destroy() {
			super.destroy();
		}
	},
} );
