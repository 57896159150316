( function() {
	let init = () => {
		if ( 'complete' !== document.readyState ) {
			return;
		}

		// noop
		init = () => {};

		const elements = document.getElementsByClassName( 'js-video-embed-player' );
		if ( 1 > elements.length ) {
			return;
		}

		for ( let i = 0; i < elements.length; i++ ) {
			const currentElement = elements[i];
			const trigger = currentElement.getElementsByClassName( 'js-video-embed-player__trigger' )[0];
			if ( !trigger ) {
				// No Trigger, let's exit here and never come back.
				return;
			}


			// Get iframe and check if it actually exists
			const iframe = currentElement.getElementsByClassName( 'js-video-embed-player__iframe' )[0];
			if ( !iframe ) {
				return;
			}

			if ( iframe.src.startsWith( 'https://www.youtube.com/' ) ) {
				// We're using Youtube
				// eslint-disable-next-line no-undef
				if ( 'undefined' === typeof( YT ) || 'undefined' === typeof( YT.Player ) ) {
					waitForYoutube( currentElement, trigger, iframe );
				} else {
					initYoutube( currentElement, trigger, iframe );
				}

				return;
			}

			if ( iframe.src.startsWith( 'https://player.vimeo.com/' ) ) {
				// We're using vimeo
				// eslint-disable-next-line no-undef
				if ( 'undefined' === typeof( Vimeo ) || 'undefined' === typeof( Vimeo.Player ) ) {
					console.warn( 'Warning: Vimeo not loaded' );
				} else {
					initVimeo( currentElement, trigger, iframe );
				}

				return;
			}
		}
	};

	const waitForYoutube = ( currentElement, trigger, iframe ) => {
		window.onYouTubeIframeAPIReady = () => {
			initYoutube( currentElement, trigger, iframe );
		};
	};

	const initYoutube = ( element, trigger, iframe ) => {
		// double check if elements exist, nothing wrong with making things sure
		if ( !element || !trigger || !iframe ) {
			return;
		}

		const playYoutube = ( e ) => {
			e.preventDefault();
			player.playVideo();
		};

		const bindYoutube = () => {
			if ( !player ) {
				return;
			}

			trigger.addEventListener( 'change', playYoutube );
		};

		// Source: https://developers.google.com/youtube/iframe_api_reference
		// eslint-disable-next-line no-undef
		const player = new YT.Player( iframe, {
			events: {
				onReady: bindYoutube,
			},
		} );
	};

	const initVimeo = ( element, trigger, iframe ) => {
		// double check if elements exist, nothing wrong with making things sure
		if ( !element || !trigger || !iframe ) {
			return;
		}

		const playVimeo = ( e ) => {
			e.preventDefault();
			if ( !player ) {
				return;
			}

			player.play().then( () => {
				if ( !element || !element.parentNode ) {
					return;
				}

				// the video is playing, lets check if muted and update state
				const mute = element.querySelector( '.js-video-embed-player__toggle-mute' );
				if ( !mute ) {
					return;
				}

				player.getMuted().then( ( muted ) => {
					if ( !muted ) {
						element.classList.add( 'is-unmuted' );
					}
				} );
			} ).catch( ( error ) => {
				if ( 'PlayInterrupted' === error.name ) {
					// we don't want to notice this as an error but a warning.
					console.warn( error );
				} else {
					throw error;
				}
			} );
		};

		// Source: https://github.com/vimeo/player.js/
		// eslint-disable-next-line no-undef
		const player = new Vimeo.Player( iframe );
		trigger.addEventListener( 'change', playVimeo );

		// Check if mute exists
		const mute = element.querySelector( '.js-video-embed-player__toggle-mute' );
		if ( !mute ) {
			return;
		}

		const muteVimeo = ( e ) => {
			e.preventDefault();
			if ( !player ) {
				return;
			}

			player.getMuted().then( ( muted ) => {
				player.setMuted( !muted ).then( ( mutedState ) => {
					if ( !element || !element.parentNode ) {
						return;
					}

					if ( !mutedState ) {
						element.classList.add( 'is-unmuted' );

						return;
					}

					element.classList.remove( 'is-unmuted' );
				} );
			} );
		};

		// listen to mute toggle
		mute.addEventListener( 'click', muteVimeo );
	};

	document.addEventListener( 'readystatechange', () => {
		init();
	}, false );

	init();
}() );
