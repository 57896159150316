import { OverlayContainer } from './overlay-container.js';
import { supportsHistoryAPI } from './util.js';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';

// Step 1
// - wait for document ready
// - add event listeners
document.addEventListener( 'readystatechange', () => {
	if ( 'complete' !== document.readyState ) {
		return;
	}

	addListeners();

}, false );


// Step 2
// - gather overlay capable "a" tags
// - add event listener on "beforeunload"
// - add event listener on "Esc"
// - add event listeners on overlay capable "a" tags
const addListeners = () => {
	const showInOverlayElements = ( Array.from( document.querySelectorAll( '.js-show-in-overlay' ) ) );

	// Do nothing if there are no overlay capable nodes.
	if ( !showInOverlayElements || !showInOverlayElements.length ) {
		return;
	}

	// If there is history API support add a listener for "beforeunload"
	// Reset the state to the overview in the callback
	if ( supportsHistoryAPI() ) {
		const originalDocumentTitle = document.title;
		const originalHref = window.location.href;

		const beforeUnload = () => {
			window.history.replaceState( {}, originalDocumentTitle, originalHref );
			window.removeEventListener( 'beforeunload', beforeUnload );
		};

		window.addEventListener( 'beforeunload', beforeUnload, false );
	}

	// Close overlays when a user presses "Esc"
	document.addEventListener( 'keydown', ( e ) => {
		const event = e || window.event;
		let isEscape = false;
		if ( 'key' in event ) {
			isEscape = ( 'Escape' === event.key || 'Esc' === event.key );
		} else {
			isEscape = ( 27 === event.keyCode );
		}
		if ( isEscape ) {
			document.dispatchEvent( new Event( 'overlay-close' ) );
		}
	}, false );

	// Add click handlers to all overlay capable nodes
	showInOverlayElements.forEach( ( element ) => {
		element.addEventListener( 'click', clickHandler, false );
	} );
};

// Click handler
// - removes itself
// - sets up an OverlayContainer
// - fetches/renders a partial
// - after this step control is handed of to the "OverlayContainer" class instances.
const clickHandler = ( e ) => {
	const source = e.currentTarget || e.srcElement;
	if ( !source ) {
		return; // nothing to do here
	}

	// Remove the listeners
	source.removeEventListener( 'click', clickHandler );

	if ( !source.href || source.href.includes( '#' ) ) {
		return;
	}

	try {
		const overlayContainer = new OverlayContainer( source );
		overlayContainer.fetchAndRenderPartial();

		e.preventDefault();
		e.stopPropagation();

	} catch ( err ) {
		console.log( err );
		bugsnagClient.notify( err );
	}
};
