import { MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrBookingDialog extends MrModalDialog {
	constructor() {
		super();
	}

	override async willOpen(): Promise<void> {
		document.body.setAttribute( 'has-open-overlay', '' );

		await super.willOpen();
	}

	override async willClose(): Promise<void> {
		document.body.removeAttribute( 'has-open-overlay' );

		await super.willClose();
	}

	override openAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: 0,
					},
					{
						opacity: 1,
					},
				],
				{
					duration: 128,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this.querySelector( '.booking-sidepanel' ),
				[
					{
						transform: 'translateX(100%)',
					},
					{
						transform: 'translateX(0)',
					},
				],
				{
					delay: 128,
					duration: 326,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this.querySelector( '.booking-sidepanel' ),
				[
					{
						transform: 'translateX(0)',
					},
					{
						transform: 'translateX(100%)',
					},
				],
				{
					duration: 256,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this,
				[
					{
						opacity: 1,
					},
					{
						opacity: 0,
					},
				],
				{
					delay: 0,
					duration: 256,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
		];
	}
}

customElements.define( 'mr-booking-dialog', MrBookingDialog );
