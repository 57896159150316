function setupMenuLocations() {
	const locationLinks = document.querySelectorAll( '[show-location-info]' );

	locationLinks.forEach( ( link ) => {
		link.addEventListener( 'mouseenter', ( event ) => {
			document.querySelectorAll( '[location-info][active]' ).forEach( ( el ) => {
				el.removeAttribute( 'active' );
			} );

			const element = event.target;
			if ( !element || !( element instanceof HTMLElement ) ) {
				return;
			}

			const targetId = element.getAttribute( 'for' );
			if ( !targetId ) {
				return;
			}

			document.querySelector( `[location-info="${targetId}"]` )?.setAttribute( 'active', 'active' );
		} );

		link.addEventListener( 'mouseout', ( event ) => {
			document.querySelectorAll( '[location-info][active]' ).forEach( ( el ) => {
				el.removeAttribute( 'active' );
			} );

			const element = event.target;
			if ( !element || !( element instanceof HTMLElement ) ) {
				return;
			}

			const defaultTargetId = element.getAttribute( 'for-default' );
			if ( !defaultTargetId ) {
				return;
			}

			document.querySelector( `[location-info="${defaultTargetId}"]` )?.setAttribute( 'active', 'active' );
		} );
	} );
}

window.addEventListener( 'load', () => {
	setupMenuLocations();
} );
