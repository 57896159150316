import { hrefToId, supportsHistoryAPI } from './util.js';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';

export class OverlayContainer {

	constructor( target ) {
		this.open = false;

		// Original state;
		this.originalDocumentTitle = document.title;
		this.originalHref = window.location.href;

		// Overlay state;
		this.title = document.title; // fallback

		// Click source
		this.target = target;

		// Click url
		this.href = target.href;

		// The node ID of the overlay container
		this.id = target.id;

		if ( !this.id ) {
			this.id = hrefToId( this.href );
		}

		// Create the overlay container node and add to DOM
		this.el = document.createElement( 'div' );
		this.el.id = 'overlay-' + this.id;
		this.el.style.display = 'none';

		document.body.appendChild( this.el );
	}

	// One time setup
	// - fetch overlay content
	// - add to DOM
	// - show the overlay
	// - replace history state
	fetchAndRenderPartial() {

		const headers = new Headers();
		const request = new Request( `${this.href}?overlay=true`, {
			method: 'GET',
			headers: headers,
			cache: 'default',
		} );

		fetch( request ).then( ( response ) => {
			if ( !response.ok ) {
				throw new Error( `mr-overlay : ${response.status} ${response.statusText}` );
			}

			return response.text();
		} ).then( ( data ) => {
			if ( !data ) {
				throw new Error( 'mr-overlay : no data' );
			}

			const partial = new DOMParser().parseFromString( data, 'text/html' );

			if ( !partial || !partial.body ) {
				throw new Error( 'mr-overlay : empty partial' );
			}

			if ( partial.title && partial.title.length ) {
				this.title = partial.title;
			}

			const partialContent = partial.body.querySelector( '#js-overlay-content' );

			if ( !partialContent || !partialContent.innerHTML ) {
				throw new Error( 'mr-overlay : no partial content' );
			}

			this.renderContainer( partialContent.innerHTML );
			this.setupListeners();

			this.openContainer();

		} ).catch( ( err ) => {
			// send to bugsnag
			console.log( err );
			bugsnagClient.notify( err );
			this.target.click();
		} );
	}

	// Render overlay content in the container and validate
	// - must have a "closer", else we make it impossible for the user to go back.
	renderContainer( innerHTML ) {
		this.el.innerHTML = innerHTML;

		this.closers = Array.from( this.el.querySelectorAll( '.js-overlay-closer' ) );
		if ( !this.closers ) {
			throw new Error( 'mr-overlay : no overlay closers' );
		}
	}

	// Add all needed event listeners
	// - click on original "a" tag -> open
	// - click on "closer" -> close
	// - receive global event "overlay-close" -> close
	setupListeners() {
		this.target.addEventListener( 'click', ( e ) => {
			this.openContainer();

			e.preventDefault();
			e.stopPropagation();
		}, false );

		this.closers.forEach( ( closer ) => {
			closer.addEventListener( 'click', ( e ) => {
				this.closeContainer();

				e.preventDefault();
				e.stopPropagation();
			}, false );
		} );

		document.addEventListener( 'overlay-close', () => {
			this.closeContainer();
		}, false );
	}

	// - close all other overlays
	// - set document title
	// - replace history state
	// - show the overlay
	openContainer() {
		if ( true === this.open ) {
			return;
		}

		// close other overlays
		document.dispatchEvent( new Event( 'overlay-close' ) );

		this.open = true;

		document.title = this.title;

		// Set the history state to the overlay
		// Do not push state as there is no way to construct the overview or overlay states from the "popstate" event.
		if ( supportsHistoryAPI() ) {
			window.history.replaceState( {}, this.title, this.href );
		}

		this.el.style.display = 'block';
		document.body.style['overflow-y'] = 'hidden';
	}

	// - set document title
	// - replace history state
	// - hide the overlay
	closeContainer() {
		if ( false === this.open ) {
			return;
		}

		this.open = false;

		document.title = this.originalDocumentTitle;

		// Set the history state to the overview
		// Do not push state as there is no way to construct the overview or overlay states from the "popstate" event.
		if ( supportsHistoryAPI() ) {
			window.history.replaceState( {}, this.originalDocumentTitle, this.originalHref );
		}

		this.el.style.display = 'none';
		document.body.style['overflow-y'] = 'auto';
	}
}
