// For nodes without an ID we use their href as a fallback
// This converts an href to something more typical for an ID
export const hrefToId = ( href ) => {
	return href.replace( /\//g, '_' ).replace( /:/g, '_' ).replace( /\./g, '_' ).split( '?' )[0];
};

// check if the client supports the history API
export const supportsHistoryAPI = () => {
	return !!( window.history && history.replaceState );
};
