import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-marquee', {
	controller: class extends BaseController {
		resolve() {
			this.items = this.el.getElementsByClassName( 'js-marquee__lane' );

			if ( 1 > this.items.length ) {
				// Keep hanging, don't activate if empty
				return new Promise( () => {} );
			}

			return super.resolve();
		}

		init() {
			// Each lane has it's own speed, just because we can 🇰🇭
			this.pixelsPerSeconds = [
				66.6666666667,
				55.5555555556,
				64,
			];
		}

		bind() {
			/*
			 * This is the magical part, We're gonna set the animation time of
			 * each lane dependant on the OG width.
			 * It's a quite easy formula:
			 * x = laneWidth / pixelsPerSeconds
			 */
			window.requestAnimationFrame( () => {
				for ( let i = 0; i < this.items.length; i++ ) {
					const animationTime = ( this.items[i].offsetWidth / 2 ) / this.pixelsPerSeconds[i % 3];
					this.items[i].style.cssText = `animation-duration: ${animationTime}s;`;
				}

				// Set the wrappers class so the css animation can kick in.
				this.el.classList.add( 'is-animating' );
			} );
		}
	},
} );
