import { MrInputSink } from '@mrhenry/wp--mr-interactive';

class MrNewsletterDialogToggle extends MrInputSink {
	#subscribeAnimationTimer = 0;

	#isRunningSubscribeAnimation = false;

	#isRunningHoverAnimation = false;

	#justRanAnAnimation = false;

	#isRunningPoofAnimation = false;

	#dialogHasBeenOpened = false;

	#clickHandler = ( e: MouseEvent ): void => {
		if ( !e ) {
			return;
		}

		this.restartSubscribeAnimationTimer();
	};

	#focusinHandler = ( e: FocusEvent ): void => {
		if ( !e ) {
			return;
		}

		this.restartSubscribeAnimationTimer();
	};

	#mouseenterHandler = ( e: MouseEvent ): void => {
		if ( !e ) {
			return;
		}

		if ( this.#isRunningSubscribeAnimation ) {
			return;
		}

		window.clearTimeout( this.#subscribeAnimationTimer );

		this.triggerHoverAnimation();
	};

	#mouseleaveHandler = ( e: MouseEvent ): void => {
		if ( !e ) {
			return;
		}

		if ( this.#isRunningSubscribeAnimation ) {
			return;
		}

		window.clearTimeout( this.#subscribeAnimationTimer );

		this.triggerPoofAnimation();
	};

	override trigger( directive: string ): void {
		if ( directive !== 'toggle' ) {
			return;
		}

		if ( this.disabled ) {
			return;
		}

		window.clearTimeout( this.#subscribeAnimationTimer );

		if ( !this.#isRunningSubscribeAnimation ) {
			this.triggerPoofAnimation();
		}

		const forEl = this.forEl;
		if ( !forEl ) {
			return;
		}

		// forEl must be a state machine that implements "updateState(directive)"
		if ( !forEl.updateState ) {
			return;
		}

		const currentState = forEl.getAttribute( 'data-state' );
		if ( 'open' === currentState ) {
			forEl.updateState( 'close' );

			return;
		}

		if ( 'closed' === currentState ) {
			forEl.updateState( 'open' );

			this.#dialogHasBeenOpened = true;

			window.clearTimeout( this.#subscribeAnimationTimer );

			if ( !this.#isRunningSubscribeAnimation ) {
				return;
			}

			const subscribeAnimationEl = this.querySelector( '#subscribe-animation' );

			if ( !subscribeAnimationEl || !( subscribeAnimationEl instanceof HTMLElement ) ) {
				return;
			}

			subscribeAnimationEl.removeAttribute( 'run' );
			this.#isRunningSubscribeAnimation = false;
			this.#justRanAnAnimation = true;

			this.triggerPoofAnimation();

			return;
		}
	}

	override connectedCallback(): void {
		super.connectedCallback();

		requestAnimationFrame( () => {
			this.#subscribeAnimationTimer = window.setTimeout( () => {
				this.triggerSubscribeAnimation();
			}, Math.floor( Math.random() * 5000 ) + 2000 );

			window.addEventListener( 'click', this.#clickHandler );
			window.addEventListener( 'focusin', this.#focusinHandler );

			this.addEventListener( 'mouseenter', this.#mouseenterHandler );
			this.addEventListener( 'mouseleave', this.#mouseleaveHandler );
		} );
	}

	override disconnectedCallback(): void {
		super.disconnectedCallback();

		window.clearTimeout( this.#subscribeAnimationTimer );

		window.removeEventListener( 'click', this.#clickHandler );
		window.removeEventListener( 'focusin', this.#focusinHandler );

		this.removeEventListener( 'mouseenter', this.#mouseenterHandler );
		this.removeEventListener( 'mouseleave', this.#mouseleaveHandler );
	}

	triggerSubscribeAnimation(): void {
		if ( this.#dialogHasBeenOpened ) {
			return;
		}
		if ( this.#isRunningSubscribeAnimation ) {
			return;
		}

		if ( this.#isRunningHoverAnimation ) {
			return;
		}

		const subscribeAnimationEl = this.querySelector( '#subscribe-animation' );

		if ( !subscribeAnimationEl || !( subscribeAnimationEl instanceof HTMLElement ) ) {
			return;
		}

		subscribeAnimationEl.setAttribute( 'run', '' );
		this.#isRunningSubscribeAnimation = true;
		this.#justRanAnAnimation = false;

		subscribeAnimationEl.addEventListener( 'animationend', () => {
			subscribeAnimationEl.removeAttribute( 'run' );
			this.#isRunningSubscribeAnimation = false;
			this.#justRanAnAnimation = true;
		} );
	}

	triggerHoverAnimation(): void {
		if ( this.#isRunningHoverAnimation ) {
			return;
		}

		const hoverAnimationEl = this.querySelector( '#hover-animation' );

		if ( !hoverAnimationEl || !( hoverAnimationEl instanceof HTMLElement ) ) {
			return;
		}

		hoverAnimationEl.setAttribute( 'run', '' );
		this.#isRunningHoverAnimation = true;
		this.#justRanAnAnimation = true;
	}

	triggerPoofAnimation(): void {
		if ( !this.#justRanAnAnimation ) {
			return;
		}

		if ( this.#isRunningPoofAnimation ) {
			return;
		}

		const poofAnimationEl = this.querySelector( '#poof-animation' );

		if ( !poofAnimationEl || !( poofAnimationEl instanceof HTMLElement ) ) {
			return;
		}

		const hoverAnimationEl = this.querySelector( '#hover-animation' );

		if ( !hoverAnimationEl || !( hoverAnimationEl instanceof HTMLElement ) ) {
			return;
		}

		hoverAnimationEl.removeAttribute( 'run' );
		this.#isRunningHoverAnimation = false;

		poofAnimationEl.setAttribute( 'run', '' );
		this.#isRunningPoofAnimation = true;
		this.#justRanAnAnimation = false;

		poofAnimationEl.addEventListener( 'animationend', () => {
			poofAnimationEl.removeAttribute( 'run' );
			this.#isRunningPoofAnimation = false;

			this.#subscribeAnimationTimer = window.setTimeout( () => {
				this.triggerSubscribeAnimation();
			}, Math.floor( Math.random() * 5000 ) + 2000 );
		} );
	}

	restartSubscribeAnimationTimer() {
		window.clearTimeout( this.#subscribeAnimationTimer );

		this.#subscribeAnimationTimer = window.setTimeout( () => {
			this.triggerSubscribeAnimation();
		}, Math.floor( Math.random() * 5000 ) + 2000 );
	}
}


customElements.define( 'mr-newsletter-dialog-toggle', MrNewsletterDialogToggle );
