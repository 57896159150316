import { doOncePerVisit, MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrPopup extends MrModalDialog {
	#hasRendered = false;

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();
	}

	override connectedCallback() {
		super.connectedCallback();

		this.getContent();
	}

	async getContent() {
		try {
			let preview = false;
			if ( this.dataset.preview && '1' === this.dataset.preview ) {
				preview = true;
			}

			const resp = await fetch( `/wp-json/popup/general.json?preview=${preview}` );
			const popupResponse = await resp.json();
			if ( !popupResponse || !popupResponse.html ) {
				return;
			}

			// open popup only if no other other overlay is opened
			if ( document.body.hasAttribute( 'has-open-overlay' ) ) {
				return;
			}

			setTimeout( () => {
				if ( popupResponse.preview ) {
					if ( !this.#hasRendered ) {
						// add content
						this.innerHTML = popupResponse.html + this.innerHTML;
						this.#hasRendered = true;
					}

					setTimeout( () => {
						this.updateState( 'open' );
					}, 1000 );

					return;
				}

				doOncePerVisit( popupResponse.id, () => {
					if ( !this.#hasRendered ) {
						// add content
						this.innerHTML = popupResponse.html + this.innerHTML;
						this.#hasRendered = true;
					}

					setTimeout( () => {
						this.updateState( 'open' );
					}, 1000 );
				} );
			}, 4000 );
		} catch ( err ) {
			console.warn( err ); // do nothing

			return;
		}
	}

	override async willOpen(): Promise<void> {
		document.body.setAttribute( 'has-open-overlay', '' );

		await super.willOpen();
	}

	override async willClose(): Promise<void> {
		document.body.removeAttribute( 'has-open-overlay' );

		await super.willClose();
	}

	override openAnimations(): Array<KeyframeEffect> {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: 0,
					},
					{
						opacity: 1,
					},
				],
				{
					duration: 128,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this.querySelector( '.popup__content' ),
				[
					{
						opacity: 0,
						transform: 'translate(-50%, -50%) translateY(2rem)',
					},
					{
						opacity: 1,
						transform: 'translate(-50%, -50%) translateZ(0)',
					},
				],
				{
					delay: 128,
					duration: 384,
					easing: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations(): Array<KeyframeEffect> {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this.querySelector( '.popup__content' ),
				[
					{
						opacity: 1,
					},
					{
						opacity: 0,
					},
				],
				{
					duration: 128,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this,
				[
					{
						opacity: 1,
					},
					{
						opacity: 0,
					},
				],
				{
					delay: 128,
					duration: 128,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
		];
	}
}

customElements.define( 'mr-popup', MrPopup );
