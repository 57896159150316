let resengoObserver : IntersectionObserver|null = null;

function initLazyLoadedResengo() {
	if ( !( 'IntersectionObserver' in window ) ) {
		const iframes = document.querySelectorAll( 'iframe[resengo-iframe][lazy-src]' );
		iframes.forEach( ( iframe ) => {
			const src = iframe.getAttribute( 'lazy-src' );
			if ( src ) {
				iframe.setAttribute( 'src', src );
				iframe.removeAttribute( 'lazy-src' );
				iframe.removeAttribute( 'resengo-iframe' );
			}
		} );

		return;
	}

	if ( !resengoObserver ) {
		resengoObserver = new IntersectionObserver( ( entries ) => {
			entries.forEach( ( entry ) => {
				if ( entry.isIntersecting ) {
					const iframe = entry.target;
					const src = entry.target.getAttribute( 'lazy-src' );
					if ( src ) {
						iframe.setAttribute( 'src', src );
						iframe.removeAttribute( 'lazy-src' );
						iframe.removeAttribute( 'resengo-iframe' );
						resengoObserver?.unobserve( iframe );
					}
				}
			} );
		} );
	}

	const iframes = document.querySelectorAll( 'iframe[resengo-iframe][lazy-src]' );
	iframes.forEach( ( iframe ) => {
		resengoObserver?.observe( iframe );
	} );
}

initLazyLoadedResengo();
requestAnimationFrame( () => {
	initLazyLoadedResengo();
} );
window.addEventListener( 'load', () => {
	initLazyLoadedResengo();
} );
