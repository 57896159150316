import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import { throttle } from '../helpers/throttle';

const selectors = {
	btnNext: '.js-mr-slideshow__next',
	btnPrev: '.js-mr-slideshow__prev',
	caption: '.js-mr-slideshow__caption',
	item: '.js-mr-slideshow__item',
	dot: '.js-mr-slideshow__dot',
};

interface SlideshowControllerWithAttributes extends SlideshowController {
	auto: number
	current: number
	loop: boolean
}

class SlideshowController extends BaseController<HTMLElement> {
	items: NodeList | null = null;

	btnNext: NodeList | null = null;

	btnPrev: NodeList | null = null;

	captions: NodeList | null = null;

	dots: NodeList | null = null;

	looper: number | null = null;

	/*
		 * Step 1
		 */
	override resolve(): Promise<void> {
		return new Promise( ( resolve ) => {
			requestAnimationFrame( () => {
				this.items = this.el.querySelectorAll( selectors.item );

				if ( 1 > this.items.length ) {
					// Keep hanging, don't activate if empty
					return;
				}

				resolve();
			} );
		} );
	}

	/*
		 * Step 2
		 */
	override init() {
		this.btnNext = this.el.querySelectorAll( selectors.btnNext );
		this.btnPrev = this.el.querySelectorAll( selectors.btnPrev );
		this.captions = this.el.querySelectorAll( selectors.caption );
		this.dots = this.el.querySelectorAll( selectors.dot );

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const _this = ( <any> this ) as SlideshowControllerWithAttributes;

		if ( !_this.current ) {
			this.setCurrent( 0 );
		}

		this.start();
	}

	/*
		 * Step 3
		 */
	override render() {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const _this = ( <any> this ) as SlideshowControllerWithAttributes;

		if ( this.items ) {
			this.items.forEach( ( item, i ) => {
				if ( !( item instanceof Element ) ) {
					return;
				}

				if ( item.classList.contains( 'is-active' ) ) {
					item.classList.remove( 'is-active' );
				}

				if ( i === _this.current ) {
					item.classList.add( 'is-active' );
				}
			} );
		}

		if ( this.captions ) {
			this.captions.forEach( ( caption, i ) => {
				if ( !( caption instanceof Element ) ) {
					return;
				}

				if ( caption.classList.contains( 'is-active' ) ) {
					caption.classList.remove( 'is-active' );
				}

				if ( i === _this.current ) {
					caption.classList.add( 'is-active' );
				}
			} );
		}

		if ( this.dots ) {
			this.dots.forEach( ( dot, i ) => {
				if ( !( dot instanceof Element ) ) {
					return;
				}

				if ( dot.classList.contains( 'is-active' ) ) {
					dot.classList.remove( 'is-active' );
				}

				if ( i === _this.current ) {
					dot.classList.add( 'is-active' );
				}
			} );
		}
	}

	/*
		 * Step 4
		 */
	override bind() {
		/**
			 * On click next button handler
			 */
		const throttledToNext = throttle( () => {
			return this.next();
		}, 256 );

		if ( this.btnNext ) {
			this.btnNext.forEach( ( el ) => {
				if ( !( el instanceof Element ) ) {
					return;
				}

				this.on( `click ${selectors.btnNext}`, ( e ) => {
					e.preventDefault();

					this.setAuto( 0 );
					throttledToNext();
				}, el );
			} );
		}

		/**
			 * On click previous button handler
			 */
		const throttledToPrev = throttle( () => {
			return this.previous();
		}, 256 );

		if ( this.btnPrev ) {
			this.btnPrev.forEach( ( el ) => {
				if ( !( el instanceof Element ) ) {
					return;
				}

				this.on( `click ${selectors.btnPrev}`, ( e ) => {
					e.preventDefault();

					this.setAuto( 0 );
					throttledToPrev();
				}, el );
			} );
		}

		/**
			 * On click pagination dot handler
			 */
		if ( this.dots ) {

			this.dots.forEach( ( dot, i ) => {
				if ( !( dot instanceof Element ) ) {
					return;
				}

				this.on( 'click', ( e ) => {
					e.preventDefault();

					this.setAuto( 0 );
					this.setCurrent( i );
				}, dot );
			} );
		}

		const touch = {
			startX: 0,
			threshold: 100,
			allowedTime: 400,
			startTime: 0,
		};

		this.on( 'touchstart', ( e: TouchEvent | Event ) => {
			if ( !( e instanceof TouchEvent ) ) {
				return;
			}

			if ( !e.changedTouches.length ) {
				return;
			}

			touch.startX = e.changedTouches[0].screenX;
			touch.startTime = new Date().getTime();
		} );

		this.on( 'touchend', ( e: TouchEvent | Event ) => {
			if ( !( e instanceof TouchEvent ) ) {
				return;
			}

			if ( !e.changedTouches.length ) {
				return;
			}

			const distX = e.changedTouches[0].screenX - touch.startX;
			if ( new Date().getTime() - touch.startTime <= touch.allowedTime &&
					Math.abs( distX ) >= touch.threshold ) {
				if ( 0 > distX ) {
					this.next();
				} else {
					this.previous();
				}
			}
		} );
	}

	/***/
	setCurrent( to: number ) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const _this = ( <any> this ) as SlideshowControllerWithAttributes;
		// let parsed = parseInt( to, 10 );
		let parsed = to;

		if ( parsed === _this.current ) {
			return;
		}

		const max = ( this.items || [] ).length;

		// If we're at the last slide and navigated 'Next'
		if ( parsed >= max ) {
			// Back to first slide if carousel has loop set to true
			if ( _this.loop ) {
				parsed = 0;
			} else {
				parsed = max - 1;
			}
		}

		// If we're at the first slide and navigated 'Previous'
		if ( 0 > parsed ) {
			// Jump to last slide if carousel has loop set to true
			if ( _this.loop ) {
				parsed = max - 1;
			} else {
				parsed = 0;
			}
		}

		_this.current = parsed;

		this.render();
	}

	setAuto( to: number ) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const _this = ( <any> this ) as SlideshowControllerWithAttributes;

		const parsed = to;

		if ( parsed === _this.auto ) {
			return;
		}

		if ( 0 >= parsed ) {
			this.el.removeAttribute( 'auto' );
		} else {
			this.el.setAttribute( 'auto', parsed.toString() );
		}

		this.start();
	}

	start() {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const _this = ( <any> this ) as SlideshowControllerWithAttributes;
		this.stop();

		if ( _this.auto && 0 < _this.auto ) {
			this.looper = window.setInterval( () => {
				this.next();
			}, _this.auto );
		}
	}

	stop() {
		if ( this.looper ) {
			window.clearInterval( this.looper );
			this.looper = null;
		}
	}

	next() {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const _this = ( <any> this ) as SlideshowControllerWithAttributes;

		this.setCurrent( _this.current + 1 );
	}

	previous() {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const _this = ( <any> this ) as SlideshowControllerWithAttributes;

		this.setCurrent( _this.current - 1 );
	}

	override destroy() {
		this.stop();
		super.destroy();
	}
}

defineCustomElement( 'mr-slideshow', {
	attributes: [
		{
			attribute: 'loop',
			type: 'bool',
		},
		{
			attribute: 'auto',
			type: 'int',
		},
		{
			attribute: 'current',
			type: 'int',
		},
	],
	controller: SlideshowController,
} );
